// i18next-extract-mark-ns-start markets-motorcycle-emissions

import { MARKETS_CAPABILITIES_EMISSIONS_NAV, MOTORCYCLE_NAV, EMISSIONS_PRODUCTS_NAV, LINKS } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import ResourceListing from '@components/views/ResourceListing';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const MarketMotorcycleEmissionsPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const image = getImage(data.image.childImageSharp.gatsbyImageData);
	const ignite = getImage(data.ignite.childImageSharp.gatsbyImageData);
	const ringpak = getImage(data.ringpak.childImageSharp.gatsbyImageData);
	const wave = getImage(data.wave.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<h2>Reducing internal combustion engine emissions</h2>
						<p>
							In today's market with increasing emissions regulations aiming for a net zero future,
							Original Equipment Manufacturers (OEMs) and manufacturers are under increasing pressure to reduce tailpipe emissions
							from Internal Combustion Engine (ICE) powertrains and show the durability of exhaust systems that are using catalytic converters,
							particularly with Euro 5+ due for motorcycles in 2024.
						</p>
						<p>
							Oil is vital to engine performance for cooling and lubrication, however burning and leaking oil adds to
							emissions and consumption of carbon-based fluids.
							Understanding how design parameters affect oil consumption is key to reducing usage whilst maximising component life and durability.
							The priority of reducing oil consumption has never been greater as motorcycle manufacturers need to meet the Euro 5+ emission standards,
							as well as developing combustion engines for non-carbon fuels including hydrogen where oil will still be used as the lubricant.
						</p>
						<p>
							The ultimate design challenge now becomes finding the balance between the fuel economy, machine performance and
							selection of the right exhaust aftertreatment components to reduce tailpipe emissions in line with new regulations.
						</p>
					</Trans>
					{image && (
						<p>
							<GatsbyImage image={image} alt="Motorcycle aftertreatment system model" />
						</p>
					)}
					<Trans i18nKey="content_2">
						<p>
							In the early stages of development physical prototype modelling is expensive, requiring virtual simulation tools to
							answer many of the 'What ifs' before committing to a design path. These predictive tools can be used to minimise emissions arising from
							combustion and Lubricant Oil Consumption (LOC), and to optimise exhaust aftertreatment.
						</p>
					</Trans>
					{ignite && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={ignite}
								alt="IGNITE"
								className="icon-image"
							/>
						</div>
					)}
					<Trans i18nKey="content_3">
						<p>
							IGNITE, a complete physics-based vehicle systems simulation package analyses the drive cycle models at the heart of exhaust aftertreatment system design.
							This includes predicting the effect of different control strategies on catalyst behaviour, assessing the thermal boundary conditions for the components,
							and reducing the fuel economy penalty of lower emissions.
						</p>
					</Trans>
					{ringpak && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={ringpak}
								alt="RINGPAK"
								className="icon-image"
							/>
						</div>
					)}
					<Trans i18nKey="content_4">
						<p>
							RINGPAK, is an advanced 2D simulation package which enable users to predict and optimise ring pack dynamics,
							lubrication, gas flow and oil consumption using both steady state and transient models.
							Motorcycles provide a specific application for RINGPAK. Unlike cars the oil lubricates the transmission and clutch as well as the engine.
							Higher phosphorus motorcycle oil must be used ensure transmission durability, which when lost into the exhaust system damages
							the catalyst and increases emissions. Therefore, predicting oil consumption, has a direct effect on reducing exhaust emissions.
						</p>
					</Trans>

					{wave && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={wave}
								alt="WAVE"
								className="icon-image"
							/>
						</div>
					)}
					<Trans i18nKey="content_5">
						<p>
							WAVE is a 1D Computational Fluid Dynamics (CFD) tool that solves the Navier-Stokes equations governing the transfer of mass,
							momentum, and energy for compressible gas flows, and includes sub-models for combustion and emissions.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Solutions")}
						className="widget__sibling__pages">
						<AssetList>
							{MOTORCYCLE_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Aside.Widget
						title={t("_OurCapabilities")}
						className="widget__sibling__pages">
						<AssetList>
							{MARKETS_CAPABILITIES_EMISSIONS_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Aside.Widget
						title={t("_Products")}
						className="widget__sibling__pages">
						<AssetList>
							{EMISSIONS_PRODUCTS_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>
		</Page>
	);
};

export default MarketMotorcycleEmissionsPage;

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(
			filter: { ns: { in: ["markets-motorcycle-emissions", "_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
				ns
				data
					language
				}
			}
		}
		banner: file(relativePath: { eq: "content/markets/motorcycle/Realis-Simulation_1325_motorcycleExhaust_cropped.jpg" }) {
			...imageBreaker
		}
		image: file(relativePath: { eq: "content/markets/motorcycle/Realis-Simulation_994_motorcycleAftertreatment.png" }) {
			...imageStandard
		}
		ignite: file(relativePath: { eq: "content/logos/IGNITE.png" }) {
			...imageBreaker
		}
		ringpak: file(relativePath: { eq: "content/products/ringpak/rs_ringpak.png" }) {
			...imageBreaker
		}
		wave: file(relativePath: { eq: "content/products/wave/rs_wave.png" }) {
			...imageBreaker
		}
		
		resources: allMarkdownRemark(
			filter: {
				fileAbsolutePath: { regex: "/resources/" }
				fields: { language: { eq: $language } }
				frontmatter: { tags: { in: "aftertreatment" } }
			}
			sort: { fields: frontmatter___date, order: DESC }
			limit: 6
		) {
			nodes {
				fields {
					slug
				}
				frontmatter {
					title
					summary
					image {
						...imageStandard
					}
					type
				}
			}
		}
	}
`;
